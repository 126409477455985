@import "../../assets/scss/magic";
.import-section-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 rem(36);
}
.import-section {
  background: c(b);
  box-shadow: inset 0 0 0 rem(200) c(hero, 0.1);
  width: 100%;
  max-width: rem(620);
  padding: rem(24);
  border-radius: rem(10);
  border: rem(1) dashed c(hero);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font: 500 rem(14) / rem(18) $f;
  color: c(hero);
  button {
    height: rem(36);
    padding: 0 rem(24);
    background: c(hero);
    color: white;
    border-radius: rem(36);
    font: 500 rem(12) $f;
    cursor: pointer;
    margin: rem(12) 0 0;
    transition: 400ms background;
    @include hover {
      background: ch(hero);
    }
  }
}
