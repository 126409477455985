@import "../../assets/scss/magic";
.icon-card {
  background: c(b);
  display: flex;
  align-items: center;
  padding: rem(12);
  overflow: hidden;
  border-radius: rem(8);
  box-shadow: 0 rem(6) rem(15) rgba(black, 0.04);
  transition: transform 400ms, box-shadow 400ms;
  > li {
    padding: rem(6) rem(16);
    flex-shrink: 0;
    &:last-child {
      flex: auto;
    }
  }
  figure {
    --si-b-opacity: 0.15;
    --si-opacity: 0.1;
    width: rem(60);
    height: rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(36);
    border-radius: 100%;
    transition: all 400ms;
  }
  p {
    font: 500 rem(11) / rem(27) $f;
    transition: 400ms color;
    color: $baseColor;
    &:not(:active) {
      cursor: pointer;
    }
    @include hover {
      color: c(hero);
    }
  }
  @include hover {
    box-shadow: 0 rem(12) rem(15) -rem(4) rgba(black, 0.04);
    transform: translateY(-rem(6));
    figure {
      color: white;
      --si-b-opacity: 1;
      box-shadow: 0 0 0 rem(10) var(--sport-color);
    }
  }
}
