@function set-color($color, $color-dark: #000000, $color-light: #ffffff) {
  @if (lightness($color) > 50) {
    @return $color-dark;
  } @else {
    @return $color-light;
  }
}
@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  } @else {
    @return rgba(var(--#{$color}-rgb), $opacity);
  }
}
@function ch($color) {
  @return var(--#{$color}-hover);
}
@function sc($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color}-sc);
  } @else {
    @return rgba(var(--#{$color}-sc-rgb), $opacity);
  }
}
@function rem($size) {
  $remSize: $size / 10;
  @if $size == 1 {
    @return #{$size}px;
  }
  @return #{$remSize}rem;
}
@function -rem($size) {
  $remSize: $size / 10;
  @if $size == 1 {
    @return -#{$size}px;
  }
  @return -#{$remSize}rem;
}
@mixin over($n1, $n2) {
  --#{$n1}: var(--#{$n2});
  --#{$n1}-hover: var(--#{$n2}-hover);
  --#{$n1}-sc: var(--#{$n2}-sc);
  --#{$n1}-rgb: var(--#{$n2}-rgb);
  --#{$n1}-sc-rgb: var(--#{$n2}-sc-rgb);
}
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
@mixin hover-not-active {
  @media (hover: hover) {
    &:hover:not(:active) {
      @content;
    }
  }
}
@mixin hover-active {
  @media (hover: hover) {
    &:hover:not(:active) {
      @content;
    }
  }
  &.active {
    @content;
  }
}
@mixin placeholder {
  $prefixList: '::-webkit-input', ':-moz', '::-moz', ':-ms-input';
  @each $prefix in $prefixList {
    &#{$prefix}-placeholder {
      @content;
      opacity: 1;
    }
  }
}
@mixin retina {
  @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}
