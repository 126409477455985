@import "../../assets/scss/magic";
header {
  position: sticky;
  top: 0;
  height: var(--header-height);
  backdrop-filter: blur(rem(6));
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: c(b, 0.9);
  transition: 200ms background;
  &:focus-within {
    background: c(b);
  }
}
