$sport-main-colors: (
        'chardonnay': #f1c27d,
        'chetWoodBlue': #877ce0,
        'crayonBlue': #156bff,
        'deepSkyBlue': #00d1ff,
        'fluorescentPink': #fc4093,
        'folly': #e20f51,
        'hawaiianTan': #8e5524,
        'indianYellow': #e1ac69,
        'mercury': #bfc2d2,
        'orange': #fca601,
        'pigmentGreen': #34a854,
        'selectiveYellow': #ffbd01,
        'springBud': #bfff02,
);

$sport-icon-color-map: (
        allsport: 'selectiveYellow',
        alpinskiing: 'deepSkyBlue',
        americanfootball: 'fluorescentPink',
        angling: 'crayonBlue',
        archery: 'selectiveYellow',
        archeryshooting: 'selectiveYellow',
        athletics: 'crayonBlue',
        aussierules: 'pigmentGreen',
        australianfootball: 'pigmentGreen',
        autoracing: 'folly',
        badminton: 'deepSkyBlue',
        ballhockey: 'deepSkyBlue',
        balls: 'selectiveYellow',
        bandy: 'deepSkyBlue',
        baseball: 'deepSkyBlue',
        basketball: 'orange',
        basketballshots: 'orange',
        beachfootball: 'indianYellow',
        beachhandball: 'chardonnay',
        beachsoccer: 'indianYellow',
        beachvolleyball: 'chardonnay',
        betonblackjack: 'mercury',
        betonpoker: 'folly',
        bettingbet: 'orange',
        biathlon: 'deepSkyBlue',
        blackfridayodds: 'folly',
        bobsleigh: 'deepSkyBlue',
        bodybuilding: 'deepSkyBlue',
        bowls: 'springBud',
        boxing: 'crayonBlue',
        bridge: 'chetWoodBlue',
        callofduty: 'mercury',
        canoe: 'crayonBlue',
        canoeing: 'crayonBlue',
        chess: 'chardonnay',
        circuit: 'pigmentGreen',
        clashroyale: 'crayonBlue',
        counterstrike: 'orange',
        counterstrikego: 'orange',
        cricket: 'pigmentGreen',
        crosscountryskiing: 'deepSkyBlue',
        crossfire: 'orange',
        curling: 'crayonBlue',
        cyberfootball: 'pigmentGreen',
        cycling: 'chardonnay',
        cyclocross: 'chardonnay',
        darts: 'folly',
        diving: 'crayonBlue',
        dota2: 'chetWoodBlue',
        dota: 'chetWoodBlue',
        ebasketball: 'orange',
        efootball: 'pigmentGreen',
        eicehockey: 'crayonBlue',
        electronicsports: 'chetWoodBlue',
        entertainment: 'selectiveYellow',
        equestrian: 'hawaiianTan',
        esports: 'chetWoodBlue',
        etennis: 'springBud',
        eurovision: 'crayonBlue',
        extremesport: 'folly',
        extremsport: 'folly',
        fencing: 'folly',
        fiba3x3: 'orange',
        fieldhockey: 'pigmentGreen',
        fifa: 'selectiveYellow',
        figureskating: 'deepSkyBlue',
        financials: 'crayonBlue',
        floorball: 'deepSkyBlue',
        football: 'pigmentGreen',
        footballpro: 'selectiveYellow',
        formula1: 'folly',
        fortnite: 'folly',
        freestyleskiing: 'deepSkyBlue',
        freestylewrestling: 'selectiveYellow',
        futsal: 'deepSkyBlue',
        futurehorseracing: 'pigmentGreen',
        gaelicfootball: 'pigmentGreen',
        gogame: 'hawaiianTan',
        3x3basketball: 'hawaiianTan',
        golf: 'pigmentGreen',
        grasshockey: 'pigmentGreen',
        grecoromanwrestling: 'folly',
        greyhounds: 'mercury',
        gymnastics: 'deepSkyBlue',
        halo: 'crayonBlue',
        handball: 'mercury',
        hardathletics: 'crayonBlue',
        hearthstone: 'orange',
        heroesofnewerth: 'selectiveYellow',
        heroesofthestorm: 'chetWoodBlue',
        hockey26: 'pigmentGreen',
        hockey: 'pigmentGreen',
        airhockey: 'pigmentGreen',
        hon: 'selectiveYellow',
        horseracing: 'hawaiianTan',
        horsseracing: 'hawaiianTan',
        hurling: 'pigmentGreen',
        icehockey: 'deepSkyBlue',
        inlinehockey: 'pigmentGreen',
        inspiredcycling: 'chardonnay',
        inspiredgreyhoundracing: 'mercury',
        inspiredhorseracing: 'chetWoodBlue',
        inspiredmotorracing: 'folly',
        inspiredrush2football: 'pigmentGreen',
        inspiredspeedway: 'mercury',
        inspiredtrotting: 'chetWoodBlue',
        judo: 'crayonBlue',
        kabaddi: 'chetWoodBlue',
        kingofglory: 'chetWoodBlue',
        lacross: 'pigmentGreen',
        lacrosse: 'pigmentGreen',
        leagueoflegends: 'folly',
        lightathletic: 'crayonBlue',
        livebetonbaccarat: 'folly',
        livebetonpoker: 'folly',
        livemonitor: 'pigmentGreen',
        lottery: 'chetWoodBlue',
        luge: 'deepSkyBlue',
        mafia: 'folly',
        minisoccer: 'pigmentGreen',
        mma: 'folly',
        modernpentathlon: 'hawaiianTan',
        mortalkombat: 'orange',
        mortalkombat11: 'orange',
        mortalkombatxl: 'orange',
        motorbike: 'folly',
        motorbikes: 'folly',
        motorsport: 'orange',
        nascar: 'folly',
        nba2k: 'folly',
        netball: 'mercury',
        nordiccombined: 'deepSkyBlue',
        olympic: 'selectiveYellow',
        olympics: 'selectiveYellow',
        oscar: 'selectiveYellow',
        outright: 'selectiveYellow',
        overwatch: 'orange',
        padel: 'pigmentGreen',
        penalty: 'pigmentGreen',
        penaltykicks: 'pigmentGreen',
        pentanque: 'chetWoodBlue',
        pesapallo: 'orange',
        pmuhorseracing: 'pigmentGreen',
        poker: 'pigmentGreen',
        pokerlive: 'pigmentGreen',
        politics: 'crayonBlue',
        pool: 'chetWoodBlue',
        ps4games: 'crayonBlue',
        pubg: 'selectiveYellow',
        pubgold: 'selectiveYellow',
        quake: 'orange',
        rainbowsix: 'mercury',
        rally: 'orange',
        rinkhockey: 'chardonnay',
        rocketleague: 'crayonBlue',
        rowing: 'crayonBlue',
        rugby7: 'pigmentGreen',
        rugby: 'pigmentGreen',
        rugbyl: 'pigmentGreen',
        rugbyleague: 'pigmentGreen',
        rugbys: 'pigmentGreen',
        rugbysevens: 'pigmentGreen',
        rugbyu: 'pigmentGreen',
        rugbyunion: 'pigmentGreen',
        sailing: 'crayonBlue',
        sambo: 'crayonBlue',
        schwingen: 'deepSkyBlue',
        shooting: 'selectiveYellow',
        shorttrackspeedskating: 'deepSkyBlue',
        sipan: 'crayonBlue',
        sisgreyhound: 'springBud',
        sishorseracing: 'springBud',
        skeleton: 'mercury',
        skijumping: 'deepSkyBlue',
        skis: 'deepSkyBlue',
        smash: 'folly',
        smite: 'orange',
        snooker: 'pigmentGreen',
        snowboard: 'deepSkyBlue',
        snowboarding: 'deepSkyBlue',
        soccer: 'pigmentGreen',
        softball: 'pigmentGreen',
        specialbet: 'folly',
        specialbets: 'folly',
        speedskating: 'deepSkyBlue',
        speedway: 'mercury',
        sportandfun: 'selectiveYellow',
        sportfight: 'folly',
        squash: 'selectiveYellow',
        starcraft2: 'pigmentGreen',
        starcraft: 'pigmentGreen',
        streetfighter5: 'folly',
        streetfighter: 'folly',
        sumo: 'chardonnay',
        surfing: 'crayonBlue',
        swimming: 'crayonBlue',
        synchronisedswimming: 'crayonBlue',
        synchronizedswimming: 'crayonBlue',
        tablehockey: 'pigmentGreen',
        tabletennis: 'selectiveYellow',
        taekwondo: 'folly',
        tankbiathlon: 'pigmentGreen',
        tekken7: 'chardonnay',
        telecast: 'selectiveYellow',
        tennis: 'selectiveYellow',
        triathlon: 'deepSkyBlue',
        trotting: 'chetWoodBlue',
        ufc2: 'folly',
        ufc: 'folly',
        ultimatefighting: 'folly',
        vainglory: 'chetWoodBlue',
        virtualcarracing: 'folly',
        virtualdogs: 'chetWoodBlue',
        virtualfootball: 'chetWoodBlue',
        virtualfootballleague: 'pigmentGreen',
        virtualgreyhounds: 'mercury',
        virtualhorseracing: 'chetWoodBlue',
        virtualhorses: 'chetWoodBlue',
        virtualsoccer: 'chetWoodBlue',
        virtualsports: 'chetWoodBlue',
        virtualtennis: 'selectiveYellow',
        volleyball: 'selectiveYellow',
        warcraftiii: 'orange',
        waterpolo: 'crayonBlue',
        weightlifting: 'crayonBlue',
        whatwherewhen: 'folly',
        worldoftanks: 'pigmentGreen',
        worldofwarcraft: 'orange',
        wrestling: 'folly',
        wwe: 'folly',
        yachting: 'crayonBlue',
        vbox: 'crayonBlue',
        marbleracing: 'folly',
        teqball: 'crayonBlue',
        pistolshooting: 'crayonBlue',
);

:root {
  @each $name, $color in $sport-main-colors {
    --smc-#{$name}: #{red($color), green($color), blue($color)};
  }
  @each $sport, $color in $sport-icon-color-map {
    --si-t-#{$sport}: var(--smc-#{$color});
    @at-root {
      .si-#{$sport} {
        --sport-color: rgba(var(--si-t-#{$sport}), var(--si-opacity, 1));
        &.si-color {
          color: rgba(var(--si-t-#{$sport}), var(--si-c-opacity, 1));
        }
        &.si-background {
          background-color: rgba(var(--si-t-#{$sport}), var(--si-b-opacity, 1));
        }
      }
    }
  }
}
