@import "magic";
:root {
  --main-font-size: 10px;
  --header-height: #{rem(80)};
  @each $name, $color in $c {
    --#{$name}: #{$color};
  }
}
* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  border-radius: 0;
  background: none;
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
  &, &:before, &:after {
    box-sizing: border-box;
  }
}
a {
  color: inherit;
  text-decoration: none;
  transition: 300ms color, 300ms background;
}
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
  appearance: none;
  display: block;
  color: inherit;
  @include placeholder {
    opacity: 1;
    font-family: inherit;
  }
}
html {
  font: var(--main-font-size) / 1.5 $f;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  max-width: 100vw;
  background: $baseBackground;
  color: $baseColor;
  user-select: none;
}
body {
  font-size: rem(14);
}
main {
  padding: rem(36);
}
li {
  display: block;
}
::selection {
  background: c(hero, 0.1);
  color: c(hero);
  -webkit-text-fill-color: c(hero);
}
