@import "../../assets/scss/magic";

$default: #007bff;
$default_dark: #0562c7;

$success: #34a853;
$success_dark: $success;

$danger: #dc3545;
$danger_dark: #bd1120;

$info: #17a2b8;
$info_dark: #138b9e;

$warning: #eab000;
$warning_dark: #ce9c09;

$awesome: #685dc3;
$awesome_dark: #4c3fb1;

$default_timer: #007bff;
$default_timer_filler: #fff;

$success_timer: #28a745;
$success_timer_filler: #fff;

$danger_timer: #dc3545;
$danger_timer_filler: #fff;

$info_timer: #17a2b8;
$info_timer_filler: #fff;

$warning_timer: #eab000;
$warning_timer_filler: #fff;

$awesome_timer: #685dc3;
$awesome_timer_filler: #fff;

.notification-container-top-center,
.notification-container-bottom-center,
.notification-container-top-left,
.notification-container-top-right,
.notification-container-bottom-left,
.notification-container-bottom-right,
.notification-container-mobile-top,
.notification-container-mobile-bottom {
  position: fixed;
  z-index: 8000;
}
.notification-container-top-center {
  transform: translateX(-50%);
  top: rem(20);
  left: 50%;
}
.notification-container-bottom-center {
  transform: translateX(-50%);
  bottom: rem(20);
  left: 50%;
}
.notification-container-top-left {
  left: rem(20);
  top: rem(20);
}
.notification-container-top-right {
  right: rem(20);
  top: rem(20);
}
.notification-container-bottom-left {
  left: rem(20);
  bottom: rem(20);
}
.notification-container-bottom-right {
  bottom: rem(20);
  right: rem(20);
}
.notification-container-mobile-top {
  right: rem(20);
  left: rem(20);
  top: rem(20);
}
.notification-container-mobile-bottom {
  right: rem(20);
  left: rem(20);
  bottom: rem(20);
  margin-bottom: -rem(15);
}
.notification-default {
  background-color: $default;
  .timer {
    background-color: $default_timer;
  }
  .timer-filler {
    background-color: $default_timer_filler;
  }
  .notification-close {
    background-color: $default;
  }
}
.notification-success {
  background-color: $success;
  .timer {
    background-color: $success_timer;
  }
  .timer-filler {
    background-color: $success_timer_filler;
  }
  .notification-close {
    background-color: $success;
  }
}

.notification-danger {
  background-color: $danger;
  .timer {
    background-color: $danger_timer;
  }
  .timer-filler {
    background-color: $danger_timer_filler;
  }
  .notification-close {
    background-color: $danger;
  }
}
.notification-info {
  background-color: $info;
  .timer {
    background-color: $info_timer;
  }
  .timer-filler {
    background-color: $info_timer_filler;
  }
  .notification-close {
    background-color: $info;
  }
}
.notification-warning {
  background-color: $warning;
  .timer {
    background-color: $warning_timer;
  }
  .timer-filler {
    background-color: $warning_timer_filler;
  }
  .notification-close {
    background-color: $warning;
  }
}

.notification-awesome {
  background-color: $awesome;
  .timer {
    background-color: $awesome_timer;
  }
  .timer-filler {
    background-color: $awesome_timer_filler;
  }
  .notification-close {
    background-color: $awesome;
  }
}
@keyframes timer {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
.notification-item {
  display: flex;
  position: relative;
  border-radius: rem(6);
  margin-bottom: rem(15);
  cursor: pointer;
  min-width: rem(320);
  .timer {
    width: 100%;
    height: rem(3);
    margin-top: rem(10);
    border-radius: rem(5);
    .timer-filler {
      height: rem(3);
      border-radius: rem(5);
    }
  }
  .notification-title {
    color: #fff;
    font-weight: 700;
    font-size: rem(14);
    margin-top: rem(5);
    margin-bottom: rem(5);
  }
  .notification-message {
    color: rgba(#fff, 0.8);
    max-width: calc(100% - #{rem(15)});
    font-size: rem(12);
    line-height: 150%;
    word-wrap: break-word;
    margin-bottom: 0;
    margin-top: 0;
  }
  .notification-content {
    padding: rem(8) rem(15);
    display: inline-block;
    width: 100%;
  }
  .notification-close {
    width: rem(18);
    height: rem(18);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: rem(10);
    top: rem(10);
    &::after {
      content: '\D7';
      position: absolute;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: rem(12);
      left: 50%;
      top: 50%;
    }
  }
}
.n-parent {
  width: rem(275);
}
.notification-container-mobile-top .notification-item,
.notification-container-mobile-bottom .notification-item,
.notification-container-mobile-top .n-parent,
.notification-container-mobile-bottom .n-parent {
  max-width: 100%;
  width: 100%;
}
.notification-container-top-right .n-parent,
.notification-container-bottom-right .n-parent {
  margin-left: auto;
}
.notification-container-top-left .n-parent,
.notification-container-bottom-left .n-parent {
  margin-right: auto;
}
.notification-container-mobile-top .n-parent,
.notification-container-mobile-bottom .n-parent {
  margin-left: auto;
  margin-right: auto;
}
