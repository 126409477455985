@import "core";

$f: 'Roboto', sans-serif;

$c: (
        hero: #146bff,
        b: white,
);

@each $name, $color in $c {
  $c: map-merge($c, (#{$name + '-hover'}: darken($color, 5)));
  $c: map-merge($c, (#{$name + '-sc'}: set-color($color)));
  $c: map-merge($c, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
  $c: map-merge($c, (#{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))}));
}

$baseBackground: #eff1f7;
$baseColor: #3c4b5a;
