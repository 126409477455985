@import "../../assets/scss/magic";
.search-input {
  width: 100%;
  max-width: rem(620);
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: rem(70);
    height: rem(58);
    background: url(../../assets/media/search-solid.svg) no-repeat 50% 50% / rem(22);
    pointer-events: none;
    opacity: 0.5;
  }
  input {
    width: 100%;
    height: rem(58);
    background: #f7f7fb;
    padding: 0 rem(36) 0 rem(60);
    border-radius: rem(58);
    font: rem(16) $f;
    caret-color: $baseColor;
    transition: 300ms background, 300ms box-shadow;
    box-shadow: 0 0 0 #f7f7fb;
    color: $baseColor;
    @include placeholder {
      color: rgba($baseColor, 0.5);
      transition: 200ms color;
    }
    &:focus {
      box-shadow: 0 0 0 rem(4) #f7f7fb;
      background: white;
      @include placeholder {
        color: rgba($baseColor, 0);
      }
    }
  }
}
